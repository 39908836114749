import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://localhost/GitHub/directory/public/api/v1',
  // baseURL: 'https://api.ooak.jp/api/v3/',
  baseURL: 'https://api.ooak.jp/api/v3',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: {Authorization : `Bearer ${localStorage.getItem("accessToken")}`}
})

Vue.prototype.$http = axiosIns

export default axiosIns