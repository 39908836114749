import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import dashboard from "./routes/dashboard";
import myPages from "./routes/my-pages";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "dashboard-ecommerce" } },
    ...dashboard,
    ...myPages,
    {
      path: "/login",
      name: "auth-login",
      component: () => import("@/views/pages/authentication/MyLogin.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/pages/miscellaneous/not-authorized",
      name: "misc-not-authorized",
      component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
      },
    },
    {
      path: "/pages/miscellaneous/under-maintenance",
      name: "misc-under-maintenance",
      component: () =>
        import("@/views/pages/miscellaneous/UnderMaintenance.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/miscellaneous/error",
      name: "misc-error",
      component: () => import("@/views/pages/miscellaneous/Error.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: "auth-login" });

  //   // If logged in => not authorized
  //   return next({ name: "misc-not-authorized" });
  // }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    // console.log('router userdata', userData.roles[0].name.toLowerCase())
    next(getHomeRouteForLoggedInUser(userData ? userData.roles[2].name.toLowerCase() : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
