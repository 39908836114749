export default [
  {
    path: "/my-pages/add-new",
    name: "add-new",
    component: () => import("@/views/apps/my-pages/CRUDPages/AddNew.vue"),
    props: (route) => ({ query: route.query }),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: "/my-pages/categories",
    name: "apps-categories",
    component: () => import("@/views/apps/my-pages/categories/Categories.vue"),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: "/my-pages/news-media",
    name: "apps-news-media",
    component: () => import("@/views/apps/my-pages/newsmedia/NewsMedia.vue"),
    props: (route) => ({ query: route.query }),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
  {
    path: "/my-pages/news-media/news-media-details",
    name: "news-media-details",
    component: () => import("@/views/apps/my-pages/newsmedia/NewsMediaDetails.vue"),
    props: (route) => ({ query: route.query }),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  }
];