import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, path) {
      return new Promise((resolve, reject) => {
        axios
          .get(path)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    postDatas(ctx, {path, payload}) {
      return new Promise((resolve, reject) => {
        axios
          .post(path, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateData(ctx, {path, payload}) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${path}/${payload.id}`, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePhotoArr(ctx, {path, payload}) {
      return new Promise((resolve, reject) => {
        axios
          .put(path, payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delData(ctx, {path, id}) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${path}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDetails(ctx, {path, id}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${path}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
